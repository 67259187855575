<template>
  <section class="page-image-header">
    <div v-if="block.data.image" v-html="block.data.image"></div>
    <div v-if="block.data.title" class="title-box">
      <h1 class="title">{{ block.data.title }}</h1>
      <span v-if="block.data.subtitle" class="subtitle mt-2">{{ block.data.subtitle }}</span>
    </div>
  </section>
</template>

<style>
.page-image-header {
  position: relative;
}
.page-image-header img {
  display: block;
  height: auto;
  max-width: 100%;
}
.page-image-header > .title-box {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
}
.page-image-header .title-box {
  color: white;
  text-transform: uppercase;
}
.page-image-header .title {
  margin-bottom: 0;
  font-size: 4vw;
  text-shadow: 0 0 10px black;
}
.page-image-header .subtitle {
  width: 100%;
  font-size: 1.75vw;
  background-color: black;
}
.page-image-header .date {
  color: lightgray;
}
</style>

<script>
export default {
  name: 'BlockPageImageHeader',
  props: {
    block: Object,
  },
  data() {
    return {};
  },
};
</script>
